import React from 'react';
import {Link} from 'gatsby';
import logo from '../img/logo.svg';
import twitter from '../img/social/twitter.svg';
import github from '../img/social/github-icon.svg';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      },
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              sebtrif
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}>
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-end">
              <Link className="navbar-item has-text-centered" to="/blog">
                posts
              </Link>
              <Link className="navbar-item has-text-centered" to="/contact">
                contact
              </Link>
              <div className="navbar-item has-text-centered">
                <a title="twitter" href="https://twitter.com/sebtrif">
                  <span className="icon" style={{color: '#55acee'}}>
                    <img
                      className="fas fa-lg"
                      src={twitter}
                      alt="Twitter"
                      style={{width: '1em', height: '1em'}}
                    />
                  </span>
                </a>
              </div>

              <div className="navbar-item has-text-centered">
                <a title="github" href="https://github.com/sebtrif">
                  <span className="icon" style={{color: '#55acee'}}>
                    <img
                      className="fas fa-lg"
                      src={github}
                      alt="Github"
                      style={{width: '1em', height: '1em'}}
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
